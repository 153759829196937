// @ts-nocheck
import { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Loader from "./components/shared/Loader";
import Alltransactions from "./pages/dashboard/Alltransactions";
const NotFound = lazy(() => import("./pages/notFound"));
const ComingSoon = lazy(() => import("./pages/comingSoon"));
const GlobalFaq = lazy(() => import("./pages/faq"));
const HealthCheckupForm = lazy(() => import("./pages/healthCheckupForms"));
const ContactUs = lazy(() => import("./pages/contactUs"));
const Logout = lazy(() => import("./pages/logout"));
const CancelationPolicy = lazy(() => import("./pages/cancelationPolicy"));
const PrivacyPolicy = lazy(() => import("./pages/privacyPolicy"));
const HomeScreen = lazy(() => import("./pages/homeScreen"));
const ESign = lazy(() => import("./pages/eSign"));
const AffiliateProgramme = lazy(() => import("./pages/affiliateProgramme"));
const Contractdrafting = lazy(() => import("./pages/contractDrafting"));
const ContractdraftingForm = lazy(() => import("./pages/contractDraftingForm"));
const ContractReview = lazy(() => import("./pages/contractReview"));
const ContractReviewForm = lazy(() => import("./pages/contractReviewForm"));
const Legalconsultation = lazy(() => import("./pages/legalConsultation"));
const LegalNotice = lazy(() => import("./pages/legalNotice"));
const Posh = lazy(() => import("./pages/posh"));
const Healthcheckup = lazy(() => import("./pages/healthcheckup"));
const AboutUs = lazy(() => import("./pages/aboutUs"));
const IPR = lazy(() => import("./pages/ipr"));
const SignUp = lazy(() => import("./pages/signup"));
const Login = lazy(() => import("./pages/login"));
const SignupVerify = lazy(() => import("./components/signup/SignupVerify"));
const SignUpSuccess = lazy(() => import("./components/signup/SignUpSuccess"));
const ESignOnlyMe = lazy(() => import("./components/esign/onlyme"));
const ESignMeAndOthers = lazy(() => import("./components/esign/meandothers"));
const ESignOthers = lazy(() => import("./components/esign/onlyothers"));
const MembershipSummary = lazy(() =>
  import("./pages/membership/MembershipSummary")
);
const ForgotPasswordSuccess = lazy(() =>
  import("./components/forgotPassword/ForgotPasswordSuccess")
);
const ResetPassword = lazy(() =>
  import("./components/forgotPassword/ResetPassword")
);
const GoogleLogin = lazy(() => import("./components/login/GoogleLogin"));
const ForgotPassword = lazy(() => import("./pages/forgotPassword"));
const LoginError = lazy(() => import("./pages/loginError"));
const TryLegal = lazy(() => import("./pages/trylegal"));
const Dashboard = lazy(() => import("./pages/dashboard"));
const DashboardBody = lazy(() => import("./pages/dashboard/Dashboard"));
const MyProfile = lazy(() => import("./pages/myProfile/Profile"));
const ESignList = lazy(() => import("./pages/eSign/eSignList"));
const ESignDetail = lazy(() => import("./pages/eSign/eSignDetail"));
const TermOfUse = lazy(() => import("./pages/termOfUse"));
const ContractReviewList = lazy(() =>
  import("./pages/contractReview/contractReviewList")
);
const ContractDraftList = lazy(() =>
  import("./pages/contractDrafting/contractDraftList")
);
const ContractReviewDetail = lazy(() =>
  import("./pages/contractReview/contractReviewDetail")
);
const ContractDraftDetail = lazy(() =>
  import("./pages/contractDrafting/contractDraftDetail")
);
const ChangePassword = lazy(() =>
  import("./pages/changePassword/ChangePassword")
);
const Pricing = lazy(() => import("./pages/pricing"));
function App() {
  return (
    <div className="bg-white">
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route
              path="/affiliateprogramme"
              element={<AffiliateProgramme />}
            />
            <Route path="/contractdrafting" element={<Contractdrafting />} />
            <Route
              path="/contractdraftingform"
              element={<ContractdraftingForm />}
            />
            <Route path="/contractreview" element={<ContractReview />} />
            <Route
              path="/contractreviewform"
              element={<ContractReviewForm />}
            />
            <Route path="/legalconsultation" element={<Legalconsultation />} />
            <Route path="/legalnotice" element={<LegalNotice />} />
            <Route path="/posh" element={<Posh />} />
            <Route path="/healthcheckup" element={<Healthcheckup />} />
            <Route path="/ipr" element={<IPR />} />
            <Route path="/cancelationpolicy" element={<CancelationPolicy />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/term_of_use" element={<TermOfUse />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/trylegal" element={<TryLegal />} />
            <Route path="/healthcheckupform" element={<HealthCheckupForm />} />
            <Route path="/esign" element={<ESign />} />
            <Route path="/esign/onlyme" element={<ESignOnlyMe />} />
            <Route path="/esign/meandother" element={<ESignMeAndOthers />} />
            <Route path="/esign/onlyothers" element={<ESignOthers />} />
            <Route path="/faq" element={<GlobalFaq />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signupsuccess" element={<SignUpSuccess />} />
            <Route
              path="/verify-email/:email/:token"
              element={<SignupVerify />}
            />
            <Route
              path="/verify-forgot-password/:email/:token"
              element={<ResetPassword />}
            />
            <Route path="/login" element={<Login />} />
            <Route path="/callback/google" element={<GoogleLogin />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route
              path="/forgotpasswordsuccess"
              element={<ForgotPasswordSuccess />}
            />
            <Route path="/logout" element={<Logout />} />
            <Route path="/loginerror" element={<LoginError />} />
            <Route path="/app" element={<Dashboard />}>
              <Route path="/app/dashboard" element={<DashboardBody />} />
              <Route
                path="/app/dashboard/alltransactions"
                element={<Alltransactions />}
              />
              <Route path="/app/changepassword" element={<ChangePassword />} />
              <Route path="/app/myprofile" element={<MyProfile />} />
              <Route path="/app/esign" element={<ESignList />} />
              <Route path="/app/esign/:order_id" element={<ESignDetail />} />
              <Route
                path="/app/contractreview"
                element={<ContractReviewList />}
              />
              <Route
                path="/app/contractdrafting"
                element={<ContractDraftList />}
              />
              <Route
                path="/app/contractreview/:id"
                element={<ContractReviewDetail />}
              />
              <Route
                path="/app/contractdrafting/:id"
                element={<ContractDraftDetail />}
              />
            </Route>
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/membership/summary" element={<MembershipSummary />} />
            <Route path="/comingsoon" element={<ComingSoon />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<HomeScreen />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
