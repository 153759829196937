import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { AllTransactionsAtom } from "../../atoms/AllTransactionsAtom";
import { Search } from "lucide-react";
import PaginationFooter from "../../components/shared/PaginationFooter‎";
import { usePageBoard } from "../../components/store/pageBoard";
import Loader from "../../components/shared/Loader";
import { Download } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";
import { toast } from "react-toastify";
import axios from "../../components/shared/axios";

function Alltransactions() {
  const [isLoading, setIsLoading] = useState(false);
  // @ts-ignore
  const allTransactionsList = useRecoilValue(AllTransactionsAtom);
  const [list, setList] = useState(allTransactionsList);
  const { currentPage } = usePageBoard();
  const renderLoading = () => {
    if (isLoading) {
      return <Loader />;
    }
  };
  // @ts-ignore
  const handleDownloadInvoice = async (orderType, orderNumber) => {
    let order_type =
      orderType === "ESIGN"
        ? "esign/order"
        : orderType === "EXPERT"
        ? "draft/expert"
        : orderType === "REVIEW"
        ? "review/order"
        : "membership/order";
    handleInvoiceDownload(order_type, orderNumber);
  };
  // @ts-ignore
  const handleInvoiceDownload = async (order_type, id) => {
    try {
      setIsLoading(true);
      const result = await axios.get(`/${order_type}/invoice/${id}`);
      if (result.status === 200) {
        if (result.data.link) {
          const link = document.createElement("a");
          link.href = result.data.link;
          link.setAttribute("download", `${id}`);
          document.body.appendChild(link);
          link.click();
        } else {
          toast.error(result.data.message);
        }
      } else {
        toast.error(result.data.message);
      }
    } catch (error) {
      toast.error("Server Error");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="h-[calc(100vh-120px)] ml-8 bg-transparent overflow-hidden w-full flex flex-col items-start justify-start">
      <div className="w-full justify-between  flex my-3">
        <p className="font-bold text-lg">All Orders</p>
      </div>
      <div className="pr-4 w-full">
        <PaginationFooter arraylength={list.length} />
      </div>

      <div className=" ml-2 w-[99%] relative overflow-x-auto overflow-y-scroll h-[calc(100vh-220px)] shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
          <thead className="text-xs text-white uppercase sticky top-0 bg-primary">
            <tr>
              <th scope="col" className="px-6 py-3 whitespace-nowrap">
                S. No.
              </th>
              <th scope="col" className="px-6 py-3 whitespace-nowrap">
                Order No.
              </th>
              <th scope="col" className="px-6 py-3 whitespace-nowrap">
                Type
              </th>
              <th scope="col" className="px-6 py-3 whitespace-nowrap">
                Email
              </th>
              <th scope="col" className="px-6 py-3 whitespace-nowrap">
                Amount
              </th>
              <th scope="col" className="px-6 py-3 whitespace-nowrap">
                Pro Discount Amount
              </th>
              <th scope="col" className="px-6 py-3 whitespace-nowrap">
                Discount Amount
              </th>
              <th scope="col" className="px-6 py-3 whitespace-nowrap">
                SubTotal Amount
              </th>
              <th scope="col" className="px-6 py-3 whitespace-nowrap">
                CGST
              </th>
              <th scope="col" className="px-6 py-3 whitespace-nowrap">
                SGST
              </th>
              <th scope="col" className="px-6 py-3 whitespace-nowrap">
                IGST
              </th>
              <th scope="col" className="px-6 py-3 whitespace-nowrap">
                Total Amount(after GST)
              </th>
              <th scope="col" className="px-6 py-3 whitespace-nowrap">
                Round Off Amount
              </th>
              <th scope="col" className="px-6 py-3 whitespace-nowrap">
                Amount Paid
              </th>
              <th scope="col" className="px-6 py-3 whitespace-nowrap">
                Coupon Code
              </th>
              <th scope="col" className="px-6 py-3 whitespace-nowrap">
                GSTIN
              </th>
              <th scope="col" className="px-6 py-3 whitespace-nowrap">
                State
              </th>
              <th scope="col" className="px-6 py-3 whitespace-nowrap">
                Order Id
              </th>
              <th scope="col" className="px-6 py-3 whitespace-nowrap">
                Payment Id
              </th>
              <th scope="col" className="px-6 py-3 whitespace-nowrap">
                Created On
              </th>
            </tr>
          </thead>
          <tbody>
            {list.slice((currentPage - 1) * 30, currentPage * 30).map((row) => (
              <tr
                // @ts-ignore
                key={row.id}
                className="bg-white border-b  hover:bg-gray-50 cursor-pointer"
              >
                <th scope="row" className="px-6 py-4">
                  {
                    // @ts-ignore
                    row.id
                  }
                </th>
                <td className="px-6 py-4 flex items-center">
                  {
                    // @ts-ignore
                    row.order_number
                  }
                  <TooltipProvider delayDuration={0}>
                    <Tooltip>
                      <TooltipTrigger>
                        <Download
                          className="h-5 w-5 text-primary ml-3"
                          onClick={() => {
                            // @ts-ignore
                            handleDownloadInvoice(row.type, row.order_number);
                          }}
                        />
                      </TooltipTrigger>
                      <TooltipContent>
                        <p className="max-w-md">Download Invoice</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </td>
                <td className="px-6 py-4">
                  {
                    // @ts-ignore
                    row.type
                  }
                </td>
                <td className="px-6 py-4">
                  {
                    // @ts-ignore
                    row.email
                  }
                </td>
                <td className="px-6 py-4">
                  {parseFloat(
                    // @ts-ignore
                    row.amount
                  ).toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })}
                </td>
                <td className="px-6 py-4">
                  {parseFloat(
                    // @ts-ignore
                    row.pro_discount_amount
                  ).toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })}
                </td>
                <td className="px-6 py-4">
                  {parseFloat(
                    // @ts-ignore
                    row.discount_amount
                  ).toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })}
                </td>
                <td className="px-6 py-4">
                  {parseFloat(
                    // @ts-ignore
                    row.subtotal_amount
                  ).toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })}
                </td>
                <td className="px-6 py-4">
                  {parseFloat(
                    // @ts-ignore
                    row.cgst
                  ).toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })}
                </td>
                <td className="px-6 py-4">
                  {parseFloat(
                    // @ts-ignore
                    row.sgst
                  ).toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })}
                </td>
                <td className="px-6 py-4">
                  {parseFloat(
                    // @ts-ignore
                    row.igst
                  ).toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })}
                </td>
                <td className="px-6 py-4">
                  {parseFloat(
                    // @ts-ignore
                    row.total_amount
                  ).toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })}
                </td>
                <td className="px-6 py-4">
                  {parseFloat(
                    // @ts-ignore
                    row.round
                  ).toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })}
                </td>
                <td className="px-6 py-4">
                  {parseFloat(
                    // @ts-ignore
                    row.final_amount
                  ).toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })}
                </td>
                <td className="px-6 py-4">
                  {
                    // @ts-ignore
                    row.coupon_code
                      ? // @ts-ignore
                        row.coupon_code
                      : "NA"
                  }
                </td>
                <td className="px-6 py-4">
                  {
                    // @ts-ignore
                    row.gstin
                      ? // @ts-ignore
                        row.gstin
                      : "NA"
                  }
                </td>
                <td className="px-6 py-4">
                  {
                    // @ts-ignore
                    row.state
                  }
                </td>
                <td className="px-6 py-4">
                  {
                    // @ts-ignore
                    row.razorpay_order_id
                  }
                </td>
                <td className="px-6 py-4">
                  {
                    // @ts-ignore
                    row.razorpay_payment_id
                  }
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {
                    // @ts-ignore
                    row.timestamp.split(" ")[0]
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {renderLoading()}
    </div>
  );
}

export default Alltransactions;
